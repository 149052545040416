import { breakpoints as sourceBreakpoints } from '@guardian/source/foundations';
import { getViewport } from './detect-viewport';
const breakpoints = {
    mobile: sourceBreakpoints['mobile'],
    tablet: sourceBreakpoints['tablet'],
    desktop: sourceBreakpoints['desktop'],
    wide: sourceBreakpoints['wide'],
};
const isSourceBreakpoint = (point) => point in sourceBreakpoints;
const isBreakpoint = (point) => point in breakpoints;
const tweakpoints = {
    mobileMedium: sourceBreakpoints['mobileMedium'],
    mobileLandscape: sourceBreakpoints['mobileLandscape'],
    phablet: sourceBreakpoints['phablet'],
    leftCol: sourceBreakpoints['leftCol'],
};
const sourceBreakpointNames = [
    'wide',
    'leftCol',
    'desktop',
    'tablet',
    'phablet',
    'mobileLandscape',
    'mobileMedium',
    'mobile',
];
let currentBreakpoint;
let currentTweakpoint;
// Get the closest breakpoint to a given width
const getPoint = (includeTweakpoint, width) => {
    const point = sourceBreakpointNames.find((point) => {
        if (isBreakpoint(point)) {
            return width >= breakpoints[point];
        }
        else if (includeTweakpoint) {
            return width >= tweakpoints[point];
        }
        return false;
    });
    // This assertion is captured in tests
    return (point ?? 'mobile');
};
const getBreakpoint = (width) => getPoint(false, width);
const getTweakpoint = (width) => getPoint(true, width);
const getCurrentBreakpoint = () => currentBreakpoint ?? getBreakpoint(getViewport().width);
const getCurrentTweakpoint = () => currentTweakpoint ?? getTweakpoint(getViewport().width);
// create a media query string from a min and max breakpoint
const getMediaQuery = ({ min, max, }) => {
    const minWidth = min
        ? typeof min === 'number'
            ? min
            : sourceBreakpoints[min]
        : null;
    const maxWidth = max
        ? typeof max === 'number'
            ? max
            : sourceBreakpoints[max] - 1
        : null;
    const minQuery = minWidth ? `(min-width: ${minWidth}px)` : null;
    const maxQuery = maxWidth ? `(max-width: ${maxWidth}px)` : null;
    return [minQuery, maxQuery].filter(Boolean).join(' and ');
};
const updateBreakpoint = (breakpoint) => {
    currentTweakpoint = breakpoint;
    currentBreakpoint = getPoint(false, sourceBreakpoints[currentTweakpoint]);
};
/**
 * We use media queries to keep track of what breakpoint we're in. This is to avoid
 * using getViewPort, which utilizes window.innerWidth which causes a reflow.
 */
const initMediaQueryListeners = () => {
    [...sourceBreakpointNames].reverse().forEach((bp, index, bps) => {
        if (isSourceBreakpoint(bp)) {
            const nextBp = bps[index + 1];
            const mql = window.matchMedia(getMediaQuery({
                min: bp,
                max: nextBp ? sourceBreakpoints[nextBp] : undefined,
            }));
            const listener = (mql) => mql.matches && updateBreakpoint(bp);
            //addListener is a deprecated method but Safari 13 and earlier versions do not support the new
            //method, so we need it here as a fallback to load ads on those versions of Safari
            if (typeof mql.addEventListener !== 'undefined') {
                mql.addEventListener('change', listener);
            }
            else if (typeof mql.addListener !== 'undefined') {
                mql.addListener(listener);
            }
            listener(mql);
        }
    });
};
const matchesBreakpoints = ({ min, max, }) => window.matchMedia(getMediaQuery({ min, max })).matches;
const hasCrossedBreakpoint = (includeTweakpoint) => {
    let was = includeTweakpoint
        ? getCurrentTweakpoint()
        : getCurrentBreakpoint();
    return (callback) => {
        const is = includeTweakpoint
            ? getCurrentTweakpoint()
            : getCurrentBreakpoint();
        if (is !== was) {
            callback(is, was);
            was = is;
        }
    };
};
initMediaQueryListeners();
export { getBreakpoint, getTweakpoint, getCurrentBreakpoint, getCurrentTweakpoint, matchesBreakpoints, hasCrossedBreakpoint, };
